import { UntypedFormGroup } from '@angular/forms';

export function createInsertBody(
  formGroup: UntypedFormGroup,
  onlyDirty = false
): any {
  const body: { [key: string]: any } = {};

  for (const controlName in formGroup.value) {
    if (formGroup.controls[controlName]?.dirty || !onlyDirty) {
      body[controlName] = formGroup.value[controlName];
    }
  }

  return body;
}

export function createUpdateBody(
  formGroup: UntypedFormGroup,
  onlyDirty = false
): any {
  const body: { [key: string]: any } = {};

  for (const controlName in formGroup.value) {
    if (formGroup.controls[controlName]?.dirty || !onlyDirty) {
      body[controlName] = formGroup.value[controlName];
    }
  }

  return body;
}

export function createPatchBody(formGroup: UntypedFormGroup): any {
  const bodyArray = [];

  for (const controlName in formGroup.value) {
    if (formGroup.controls[controlName].dirty) {
      bodyArray.push({
        op: 'replace',
        path: `/${controlName}`,
        value: formGroup.value[controlName],
      });
    }
  }

  return bodyArray;
}
