import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";

@Injectable({
  providedIn: 'root',
})
export class ToQueryString {

  toQueryStringParams<T>(obj: T): { observe: 'response', params: HttpParams } {
    let params = new HttpParams();

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        let val = obj[key];

        // Only process values that are not null, undefined, or empty string
        if (val !== null && val !== undefined) {
          if (Array.isArray(val)) {
            if (val.length > 0) {
              // Append each array element if the array is not empty
              val.forEach(element => {
                params = params.append(key, element.toString());
              });
            }
          } else if (val instanceof Date) {
            // Format date values
            params = params.append(key, moment(val).format('YYYY-MM-DD'));
          } else if (val !== '') {
            // Append non-empty string values
            params = params.append(key, val.toString());
          }
        }
      }
    }

    return { observe: 'response', params };
  }
}
